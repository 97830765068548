/* Vendor imports */
@import "vendor/_bootstrap";
@import "vendor/_font-awesome";
@import "vendor/hover";

/* App imports */
@import "app/_variables";
@import "app/_greyscale";


.box {
	overflow: hidden;
}

.inner {
	margin-bottom: 20px;
	border: 1px solid #e7e7e7;
}

.actual-title {
	font-size: 14px;
    margin: 0;
    padding: 10px;
}

.actual-meta {
    color: #2d2d2d;
    display: inline-block;
    font-size: 8px;
    margin-bottom: 2px;
    vertical-align: middle;
}

.actual-content p {
	font-size: 14px;
	padding: 10px;
}

.box-content {
    height: 200px;
}

